
import { defineComponent } from 'vue'
import DeviatingVehicleCompositionTable from '@/components/tools/deviating-vehicle-composition/DeviatingVehicleCompositionTable.vue'

export default defineComponent({
  name: 'DeviatingVehicleComposition',

  components: {
    DeviatingVehicleCompositionTable,
  },

  setup() {
    return {}
  },
})
