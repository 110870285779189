import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { getDeviatingVehicleComposition } from '@/api/deviating-vehicle-composition/getDeviatingVehicleComposition'
import { DeviatingVehicleComposition } from '@/types/deviating-vehicle-composition'

interface FetchParams {
  from: string
  to: string
}

interface UseDeviatingVehicleComposition {
  data: Ref<DeviatingVehicleComposition[]>
  fetchAll: (params: FetchParams) => Promise<DeviatingVehicleComposition[]>
  loading: ComputedRef<Loading>
}

const data = ref<DeviatingVehicleComposition[]>([])
const { state } = useLoading()

export function useDeviatingVehicleComposition(): UseDeviatingVehicleComposition {
  async function fetchAll(params: { from: string; to: string }) {
    state.getAll = true
    return new Promise<DeviatingVehicleComposition[]>((resolve, reject) => {
      getDeviatingVehicleComposition(params)
        .then(({ data: deviatingVehicleComposition }) => {
          data.value = deviatingVehicleComposition
          resolve(deviatingVehicleComposition)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
