import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DeviatingVehicleComposition } from '@/types/deviating-vehicle-composition'

interface FetchParams {
  from: string
  to: string
}

export function getDeviatingVehicleComposition(
  params: FetchParams
): Promise<AxiosResponse<DeviatingVehicleComposition[]>> {
  return instance.get(`/deviating-vehicle-composition`, {
    params,
  })
}
