<template>
  <q-table
    dense
    :class="$style.stickyHeader"
    :loading="loading.getAll"
    :filter="filterText"
    :rows-per-page-options="[0]"
    :rows="deviatingVehicleComposition"
    :columns="columns"
    row-key="uuid"
    style="height: calc(100vh - 50px - 68px)"
    flat
  >
    <template v-slot:top>
      <h6 class="q-ma-none">Avvikande Fordonssammansättning</h6>

      <q-space />

      <AppFieldDateRange
        :square="false"
        dense
        range
        v-model="period"
        class="q-mr-sm"
      />

      <q-input
        style="width: 100%; max-width: 300px"
        debounce="300"
        v-model="filterText"
        placeholder="Sök..."
        dense
        outlined
      >
        <template v-slot:append>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>

      <q-btn
        :loading="exporting"
        @click="onExport"
        class="q-ml-md"
        icon-right="mdi-file-export-outline"
        label="Export"
        color="grey-7"
        outline
      />
    </template>
  </q-table>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { QTable } from 'quasar'
import { format } from 'date-fns'
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useDeviatingVehicleComposition } from '@/composable/useDeviatingVehicleComposition'
import AppFieldDateRange from '@/components/AppFieldDateRange.vue'
import { DeviatingVehicleComposition } from '@/types/deviating-vehicle-composition'

export default defineComponent({
  name: 'DeviatingVehicleCompositionTable',

  components: {
    AppFieldDateRange,
  },

  setup() {
    const {
      fetchAll: fetchAllDeviatingVehicleComposition,
      data: deviatingVehicleCompositionData,
      loading,
    } = useDeviatingVehicleComposition()
    const { can } = useProfile()
    const exporting = ref(false)

    const period = ref({
      from: format(new Date(), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'departureDate',
        label: 'Avgångdatum',
        field: 'departureDate',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        align: 'left',
        name: 'technical',
        label: 'Tåguppdrag',
        field: 'technical',
        sortable: true,
      },

      {
        align: 'left',
        name: 'from',
        label: 'Från',
        field: 'from',
        sortable: true,
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        sortable: true,
      },
      {
        align: 'left',
        name: 'timeStart',
        label: 'Avg.',
        field: 'timeStart',
        sortable: true,
        format: (v: string) => format(new Date(v), 'HH:mm'),
      },
      {
        align: 'left',
        name: 'timeEnd',
        label: 'Ank.',
        field: 'timeEnd',
        sortable: true,
        format: (v: string) => format(new Date(v), 'HH:mm'),
      },
      {
        align: 'left',
        name: 'plannedComposition',
        label: 'Planerade fordonstyper',
        field: 'plannedComposition',
        sortable: true,
      },
      {
        align: 'left',
        name: 'plannedVehicles',
        label: 'Planerade fordon',
        field: 'plannedVehicles',
        sortable: true,
      },
      {
        align: 'left',
        name: 'actualComposition',
        label: 'Faktiska fordonstyper',
        field: 'actualComposition',
        sortable: true,
      },
      {
        align: 'left',
        name: 'actualVehicles',
        label: 'Faktiska fordon',
        field: 'actualVehicles',
        sortable: true,
      },
    ]

    const deviatingVehicleComposition = computed(() => {
      return deviatingVehicleCompositionData.value.filter((x) => {
        return x.actualComposition && x.plannedComposition
      })
    })

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Avvikande Fordonssammansättning',
        sheets: [
          {
            name: 'Avvikande Fordonssammansättning',
            data: deviatingVehicleComposition.value.map((item) => {
              return columns?.reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof DeviatingVehicleComposition]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    watch(period, fetchAllDeviatingVehicleComposition, {
      immediate: true,
    })

    return {
      columns,
      loading,
      deviatingVehicleComposition,
      exporting,
      can,
      onExport,
      period,
      filterText: ref(''),
    }
  },
})
</script>

<style lang="scss" module>
.stickyHeader {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
